import React, { useEffect } from 'react';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, isSticky }) => {
  useEffect(() => {}, []);

  return (
    <div>
      <Header isSticky={isSticky} />
      <main className='main-wrapper' id='main-wrapper'>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
