import React, { useEffect, useRef } from 'react';

import Logo from '../assets/images/header/header-logo.png';
import EmailImg from '../assets/images/header/email-icon.png';
import FacebookImg from '../assets/images/header/facebook-new.png';
import TwitterImg from '../assets/images/header/twitter-squared.png';
import YoutubeImg from '../assets/images/header/youtubeicon.png';
import InstagramImg from '../assets/images/header/instaicon.png';

import { Navbar, Nav , NavDropdown,Button} from 'react-bootstrap';

import { Link } from 'gatsby';

const Header = ({ isSticky }) => { 
  const ref = useRef(null);

  const scrollToSection = () => {
    document.querySelectorAll('a.scroller').forEach(function (a) {
      a.addEventListener('click', function (event) {
        if (event.target.getAttribute('href')) {
          event.preventDefault();
          const hash = event.target.getAttribute('href');
          const hashSection = hash.split('/#').pop();
          const scrollTarget = document.querySelector(`#${hashSection}`);
          const headerHeight = 60;
          if (scrollTarget) {
            window.scrollTo({
              top: scrollTarget.offsetTop - headerHeight,
              behavior: 'smooth',
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    scrollToSection();
    if (isSticky) {
      const handleScroll = () => {
        if (ref.current && ref.current.classList) {
          if (window.scrollY > 10) {
            ref.current.classList.add('sticky');
          } else {
            ref.current.classList.remove('sticky');
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', () => handleScroll);
      };
    }
  }, []);

  return (
  
    <header id='header' className='sticky-top' ref={ref}>
      <div className='top-menu bg-white'>
        <div className='container'>
          <div class="row align-items-center ">
                <div class="col-md-5">
                     <Link to='/'>
                          <img src={Logo} alt='Logo' className='main-logo' />
                    </Link>
                </div>
                <div class="col-md-4 fit-content">
                    <div class="row align-items-center float-end">
                        <div class="col-md-2 fit-content">
                            
								<img src={EmailImg} alt='Logo' />
							
                        </div>
                        <div class="col-md-10 fit-content email-gap">
                            <h4 class="email-label">Email</h4>
                            <a href="mailto:education@heartfulness.org" class="text-decoration-none text-dark email">education@heartfulness.org</a>
                        </div>
                    </div>
                </div>
                <div class=" col-md-3 fit-content">
                    <div class="row float-end">
                        <div class="col">
                        <Link to='https://www.facebook.com/practiceheartfulness/' target='_blank'>
								<img src={FacebookImg} alt='Logo' class='f-icon' />
							  </Link>
                           <Link to='https://twitter.com/heartfulness' target='_blank'>
								<img src={TwitterImg} alt='Logo' class='f-icon'/>
							  </Link>
                           <Link to='https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg' target='_blank'>
								<img src={YoutubeImg} alt='Logo' class='f-icon'/>
							  </Link>
                           <Link to='https://www.instagram.com/heartfulness/' target='_blank'>
								<img src={InstagramImg} alt='Logo' class='f-icon'/>
							  </Link>

                        </div>
                    </div>
                </div>
                
                <Navbar collapseOnSelect expand='lg'>
                
                <Navbar.Toggle />
                <Navbar.Collapse >
                                <Nav className="navbar navbar-expand-lg navbar-light bg-light w-100">
                                                              
                                    <Nav.Link href="#home">Home</Nav.Link>
                                    <Nav.Link href="#Services">Programs</Nav.Link>
                                    <Nav.Link href="#Facilities">Facilities</Nav.Link>
                                    <Nav.Link href="#gallery">Gallery</Nav.Link>
                                    <Nav.Link href="#upcoming">Upcoming Events</Nav.Link>
                                    <Nav.Link href="#Contact">Contact Us</Nav.Link>
                                  
                                   
                                </Nav>
                            </Navbar.Collapse>
              </Navbar>
    
            </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
