import React from 'react';

import FacebookImg from '../assets/images/header/facebook-new.png';
import TwitterImg from '../assets/images/header/twitter-squared.png';
import YoutubeImg from '../assets/images/header/youtubeicon.png';
import InstagramImg from '../assets/images/header/instaicon.png';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center py-3 bg-fo">
                    <p class="f-desc text-white">www.heartfulnesseducationtrust.org</p>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12 text-center p-0 ">
                    <p class=" f-desc2 text-white pt-1 ">Heartfulness practices with a certified trainer are always
                        free of fees or
                        charges whether in person or online.</p>
                </div>
                <div class="f-desc2">
                    <div class="col-md-12 text-center pb-4 pt-2">
                    <Link to='https://www.facebook.com/practiceheartfulness/' target='_blank'>
                               <img src={FacebookImg} alt='Logo' />
                          </Link>
                          <Link to='https://twitter.com/heartfulness' target='_blank'>
                               <img src={TwitterImg} alt='Logo' />
                          </Link>
                          <Link to='https://www.youtube.com/channel/UCoG2o8WtvYh8sCS40pUFtCg' target='_blank'>
                               <img src={YoutubeImg} alt='Logo' />
                          </Link>
                          <Link to='https://www.instagram.com/heartfulness/' target='_blank'>
                               <img src={InstagramImg} alt='Logo' />
                          </Link>

                    </div>
                </div>
            </div>


            <div class="row footer-color py-3">
                <div class="col-md-8 text-start">
                    <p href="#" class="f-desc3 text-decoration-none">
                        Copyright 2022 Sahaj Marg Spirituality Foundation, All Rights Reserved
                    </p>
                </div>

                <div class="col-md-4 text-end ">
                <a href="https://heartfulness.org/en/privacy-policy/" class="f-desc3 text-decoration-none px-1">
                        Privacy Policy
                    </a>
                    <a href="https://heartfulness.org/en/terms/" class="f-desc3 text-decoration-none px-1">
                        Terms of Use
                    </a>
                </div>
            </div>

        </div>
    </footer>
  );
};

export default Footer;
